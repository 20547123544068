/*********************************************
:: News Page                   
********************************************/
.page-news {

    .news-container {
        padding: 80px 0 60px;

        background: #f9f9f9;

        @include mappy-bp(mediumish){ 
            padding: 60px 0 80px;
        }

        article {
            position: relative;
            width: 100%;
            max-width: 520px;
            margin: 0 auto 20px;

            font-size: 0;

            overflow: hidden;
            border-radius: 2px;
            
            @include mappy-bp(medium){  width: calc(50% - 10px); }

            a {
                display: inline-block;
                width: 100%;
                height: 100%;

                font-size: 0;
            }

            img {
                display: block;
                max-height: 340px;

                transform: scale(1) translateZ(0);
                transition: all 0.3s ease-in-out;
            }

            .content {
                position: absolute;
                bottom: 0;
                left: 0;

                width: 100%;
                padding: 20px 16px 20px 20px;

                background: rgba(black, 0.75);

                p {
                    margin-bottom: 2px;

                    font-size: 12px;
                    font-weight: 600;
                    line-height: 18px;
                    color: white;
                }

                h4 {
                    margin-bottom: 16px;

                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: white;
                }
            }
        }

        article:hover {
            img {
                transform: scale(1.03) translateZ(0);
            }
        }

        button {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #a9a9a9;
        }

        .load-wrap {
            margin: 25px 0 0;
            padding: 15px 0 0;

            text-align: center;

            border-top: 2px solid #dddddd;
        }
    }
    /********************************************/
    
}
