/*********************************************
:: Typography
********************************************/

html, body {
    font-family: $font-family-1;
    font-weight: $font-normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6 {
    margin-bottom: 30px;

    font-family: $font-family-1;
    font-weight: $font-semibold;
    color: $color-4;
}

h1 {
    font-size: 28px;
    line-height: 35px;

    @include mappy-bp(mediumish){
        font-size: 40px;
        line-height: 50px;
    }
}

h2 {
    font-size: 18px;
    line-height: 28px;

    @include mappy-bp(mediumish) {
        font-size: 32px;
        line-height: 42px;
    }
}

h3 {
    font-size: 16px;
    line-height: 26px;

    @include mappy-bp(mediumish) {
        font-size: 22px;
    }
}

h4 {
    font-size: 16px;
    line-height: 24px;

    @include mappy-bp(mediumish){
        font-size: 20px;
    }
}

h5 {
    font-size: 16px;
    line-height: 24px;

    @include mappy-bp(mediumish){
        font-size: 18px;
    }
}

h6 {
    font-size: 16px;
    line-height: 24px;

    @include mappy-bp(mediumish){
        font-size: 16px;
    }
}

/*******************************************/
//Main

p, li, td, th, a, mark, time, dl, label, address {
    margin-bottom: 15px;

    font-size: 12px;
    line-height: 20px;
    line-height: 24px;
    color: $color-3;

    @include mappy-bp(mediumish){
        font-size: 16px;
        line-height: 28px;
    }

    a, li, p, mark, cite, time {
        font-size: 1em;
    }
}

a {
    margin: 0px;
    font-weight: $font-normal;
    text-decoration: none;
    color: $color-link;
    transition: all .5s ease-in-out;

    &:hover {
        color: $color-link-hover;
    }
}

small {
    font-size: 70%;
}

strong, b {
  font-weight: $font-bold;
}

em, i {
  font-style: italic;
}

hr {
    display: block;
    width: 100%;
    height: 1px;
    margin-bottom: 21px;

    background: rgba(white, 0.5);
    border: none;
    outline: none;
}


/*******************************************/
// Lists

dt {
    font-weight: $font-bold;

    &:after{
        content: ':';
    }
}

dt:after {
  content: ": ";
}


ul li {
    position: relative;
    list-style-type: none;
    text-indent: 0px;
    // &:before{
    //     content: "";
    //     width: 13px;
    //     height: 13px;
    //     background: $color-1;
    //     position: absolute;
    //     left: -24px;
    //     top: 6px;
    //     display: block;
    //     border-radius: 100%;
    // }
}

ol li {
    list-style-type: decimal;
}



/*******************************************/
// Code

code, pre, samp, kbd, var{
    margin: 0px;
    padding: 1px 5px 1px 5px;

    color: white;
    background: #2D747E;
    white-space: pre-wrap;
}

pre{
    padding: 10px;
}

/*******************************************/
// Blockquote

blockquote,
.blockquote {
    position: relative;
    display: block;

    font-size: 23px;
    font-weight: 400;
    line-height: 32px;
    color: #575656;
}

/*******************************************/
//Styles for Content Areas (wysiwyg)

.wysiwyg {
  text-align: left;

    img, iframe {
        width: 100%;
        margin: 0 auto 40px auto;
    }

    iframe {
        min-height: 240px;
        @include mappy-bp(mediumish){ min-height: 387px; }
    }

    ul {
        margin-bottom: 25px;
        
        li {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: black;
            list-style-type: disc;
        }
    }

    p {
        margin-bottom: 25px;

        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: black;
    }
}

.leadcopy {
    margin-bottom: 20px;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $color-1;

    @include mappy-bp(mediumish){
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
    }
}

.detail-title {
    margin-bottom: 10px;

    font-size: 20px;
    font-weight: 400;
    line-height: 42px;
    color: #2c2c2c;

    @include mappy-bp(mediumish){
        margin-bottom: 20px;

        font-size: 28px;
    }
}

/*******************************************/
//Typekit Trick

.wf-loading h1,
.wf-loading h2,
.wf-loading p,
.wf-loading a{
    visibility: hidden
}