/*********************************************
:: Layout
********************************************/

//
.titanic {
    float: none;
}

// Remove blue highlight on click
* { -webkit-tap-highlight-color: rgba(0,0,0,0); }

// body {
//     width: 100%;
//     overflow-x: hidden;
// }

/*******************************************/
// Main <section class="main" role="main">
.main {
    display: block;
    width: 100%;
    min-height: calc(100vh - #{$header-height-mobile + $footer-height-mobile});

    @include mappy-bp(mediumish){
        min-height: calc(100vh - #{$header-height-desktop + $footer-height-desktop});
    }
}

/*******************************************/
// Main <aside class="sidebar" role="complementary"> element
.sidebar {
    float: left;
    width: 100%;
    padding: 20px 15px 20px 15px;

    @include mappy-bp(mediumish){
        padding: 45px 0px 45px 0px;
    }
}

/*******************************************/
// Flex
.flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;

    &.flex-horizontal-center { align-items: center; }

    &.flex-around { justify-content: space-around; }
    &.flex-between { justify-content: space-between; }
    &.flex-center { justify-content: center; }
}
/*******************************************/
// Clear
.clear-after {
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

/*******************************************/
// Content Max Width
.content-max-width {
    position: relative;
    width: 100%;
    padding: 0 6%;
    margin: 0 auto;

    @include mappy-bp(mediumish){
        max-width: 940px;
        padding: 0;
    }
    @include mappy-bp(largeish){ max-width: 1060px; }
}

/*******************************************/
// Content Slider Width
.content-slider-width {
    position: relative;
    padding: 0;

    @include mappy-bp(mediumish){
        max-width: 940px;
        margin: 0 auto;
    }
    @include mappy-bp(largeish){ max-width: 1060px; }
}

/*******************************************/
// Narrow Content Max Width
.content-narrow-width {
    position: relative;
    padding: 0 6%;

    @include mappy-bp(mediumish){
        max-width: 690px;
        margin: 0 auto;
        padding: 0;
    }
}

/*******************************************/
// Breakpoints
.mob, .mobile {
    @include mappy-bp(mediumish){
        display: none !important;
    }
}

.desk, .desktop {
    @include mappy-bp(max-width mediumish){
        display: none !important;
    }
}

