/*********************************************
:: About Page                   
********************************************/
.page-about {

    .body-container {
        padding: 20px 0 60px;

        background-color: #f9f9f9;

        @include mappy-bp(mediumish){
            padding: 60px 0 49px;
        }

        .leadcopy {
            margin-bottom: 30px;
            @include mappy-bp(mediumish){ margin-bottom: 60px; }
        }

        .col {
            width: 100%;
            @include mappy-bp(mediumish){
                width: 50%;
            }

            h2 {
                max-width: 430px;
            }

            img.hero {
                max-width: 448px;
                margin-bottom: 56px;

                @include mappy-bp(medium mediumish){ 
                    display: inline-block;
                    max-width: 300px;
                }
            }

            .blockquote {
                max-width: 340px;

                @include mappy-bp(medium mediumish){ 
                    display: inline-block;
                    vertical-align: top;
                    max-width: 300px;
                    margin: 0 0 0 20px;
                }
            }
        }

        .col.right {
            @include mappy-bp(mediumish){
                max-width: 544px;
            }
        }
    }
    /********************************************/
    
}
