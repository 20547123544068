/*********************************************
  :: Lightbox Custom Styles (for: Magnific Popup)
********************************************/

/* Overiding Plugin Styles */
button.mfp-close {
    position: absolute;
    top: -30px;
    right: -40px;
    z-index: 1100;

    width: 38px;
    height: 38px;

    font-family: $font-family-1;
    font-size: 26px;
    font-weight: 300;
    line-height: 38px;
    text-align: center;
    color: white;

    background: transparent;
    border-radius: 50%;

    opacity: 1 !important;
    transition: all 0.4s ease-in-out;
}

.mfp-bg {
    z-index: 999999;
}

.mfp-wrap {
    z-index: 9999999;
}

.mfp-container {
    right: 0px;
    max-width: 495px;
    margin: auto;
    border-radius: 4px;
}


/* Default Fade-zoom animation */
.mfp-zoom-in .lightbox-popup { /* start state */
    opacity: 0;
    transform: scale(0.9);
    transition: all 0.4s ease-in-out;
}
.mfp-zoom-in.mfp-ready .lightbox-popup,
.mfp-zoom-in.mfp-ready .mfp-close{ /* animate in */
     opacity: 1;
     transform: scale(1.0);
}
.mfp-zoom-in.mfp-removing .lightbox-popup,
.mfp-zoom-in.mfp-removing .mfp-close { /* animate out */
    opacity: 0;
    transform: scale(0.9);
}

.mfp-zoom-in.mfp-bg { /* Dark overlay, start state */
    opacity: 0;
    transition: all 0.3s ease-out;
    background:rgba(0,0,0,0.69);
}
.mfp-zoom-in.mfp-ready.mfp-bg { /* animate in */
    opacity: 1;
}
.mfp-zoom-in.mfp-removing.mfp-bg { /* animate out */
    opacity: 0;
}




