/*********************************************
:: People Page                   
********************************************/
.page-our-people {

    .people-container {
        padding: 30px 0 60px;

        background: #f9f9f9;

        @include mappy-bp(mediumish){ 
            padding: 60px 0 80px;
        }

        // .content-max-width { max-width: 1030px; }

        article {
            position: relative;
            width: 100%;
            max-width: 490px;
            margin-bottom: 40px;

            font-size: 0;

            @include mappy-bp(mediumish){ 
                width: calc(50% - 40px);

                &:nth-of-type(odd) { margin-right: 40px; }
            }

            img {
                max-height: 456px;
                margin: auto;

                @include mappy-bp(mediumish){ 
                    width: 100%;
                    height: 456px;
                    object-fit: cover;
                }
            }

            .content {
                position: relative;

                width: calc(100% - 80px);
                margin-top: -70px;
                padding: 24px 10px 24px 0;

                background: #f9f9f9;
                
                h3 {
                    margin-bottom: 2px;

                    font-size: 28px;
                    font-weight: 400;
                    line-height: 42px;
                }

                p.title {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 30px;
                    color: $color-1;
                }

                .text {

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    /********************************************/
    
}
