/*********************************************
:: Buttons                             
********************************************/

button {
    outline: none;
}

/*******************************************/
// Default Button

button.button,
input.button,
a.button {
    display: inline-block;
    height: 40px;
    padding: 0px 12px;

    font-family: $font-family-2;
    font-size: 14px;
    font-weight: $font-semibold;
    line-height: 38px;
    text-decoration: none;
    text-align: center;
    color: $color-1;

    background: transparent;
    border: 1px solid $color-1;
    transition: all .4s ease-in-out;

    &:hover {
		color: $color-1;
    }
}

/*******************************************/
// Disabled Button Styles

.button-disabled,
.button[disabled],
button[disabled],
button[type="submit"][disabled],
input[type="submit"][disabled],
input[type="button"][disabled],
input[type="reset"][disabled]{
	color:white;
	opacity: 0.5;
	cursor: default;
}

