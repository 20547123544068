/*********************************************
:: Home Page                   
********************************************/
.page-home {
    margin-top: -$header-height-mobile;
    @include mappy-bp(mediumish){
        margin-top: -$header-height-desktop;
    }

    .hero-container {
        position: relative;
        width: 100%;
        min-height: 342px; 

        @include bg(cover);

        @include mappy-bp(medium){ min-height: 500px; }
        @include mappy-bp(mediumish){ min-height: 640px; }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            background: rgba(black, 0.6);
        }

    	.content {
            padding-top: 129px;

    		@include mappy-bp(mediumish){
    			padding-top: 344px;
    		}

    		h1 {
                max-width: 700px;

    			color: white;

                @include mappy-bp(mediumish){ margin-bottom: 39px; }
    		}

            .links {
                width: 100%;
                padding: 20px 0;

                border-top: 1px solid rgba(255, 255, 255, 0.5);

                @include mappy-bp(mediumish){ padding: 57px 0 0; }

                a {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 10px;

                    font-size: 0;

                    @include mappy-bp(mediumish){
    			        width: 33.33%;
                        max-width: 360px;
                        margin-bottom: 0;
                        padding-right: 20px;
    		        }

                    .number {
                        display: inline-block;
                        vertical-align: top;

                        width: 38px;
                        height: 38px;
                        margin-right: 12px;

                        font-size: 14px;
                        line-height: 36px;
                        text-align: center;
                        color: white;

                        border: 1px solid rgba(255, 255, 255, 0.5);
                        border-radius: 50%;
                    }

                    .text {
                        display: inline-block;
                        width: calc(100% - 50px);

                        p {
                            margin: 0;

                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                            color: white;
                        }
                    }
                }
            }
    	}
    }
    /********************************************/

    .info-container {
        padding: 40px 0;

        text-align: center;

        @include mappy-bp(mediumish){
            padding: 107px 0 80px;

            text-align: left;
        }

        .padding-bottom {
            padding-bottom: 20px;

            @include mappy-bp(mediumish){
                padding-bottom: 94px;
            }
        }

        .col {
            width: 100%;
            @include mappy-bp(mediumish){
                width: 50%;
            }

            h2 {
                max-width: 430px;
                @include mappy-bp(max-width mediumish){
                    margin: 0 auto 30px;
                }
            }
        }

        .col.content {
            @include mappy-bp(mediumish){ padding: 10px 0 0 10px; }
        }

        .col.image-collage {
            margin-bottom: 60px;

            @include mappy-bp(mediumish){ 
                order: 1;
                margin-bottom: 0;
                padding-left: 10px;
            }

            img { width: calc(50% - 10px); }
        }

        .col.service-links {
            text-align: left;

            @include mappy-bp(mediumish){ order: 0; }

            .links {
                @include mappy-bp(mediumish){ max-width: 430px; }
            }

            a {
                position: relative;

                display: inline-block;
                width: 100%;
                margin-bottom: 21px;
                padding-bottom: 25px;

                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                color: $color-1;

                border-bottom: 1px solid #9b9b9b;

                span {
                    position: absolute;
                    top: 4px;
                    right: 4px;

                    font-size: 20px;
                    color: $color-grey;
                }
            }
        }
    }
    /********************************************/

    .news-container {
        padding: 80px 0 60px;

        background: #f9f9f9;

        .title {
            position: relative;

            h3 {
                margin-bottom: 40px;

                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                color: #3f3f3f;

                .line {
                    display: inline-block;
                    vertical-align: middle;
                    width: 40px;
                    height: 1px;
                    margin: 0 10px 0 6px;

                    background-color: #9b9b9b;

                    @include mappy-bp(max-width mediumish){ display: none; }
                }

                strong {
                    font-weight: 600;
                    color: #2c2c2c;

                    @include mappy-bp(max-width mediumish){
                        display: inline-block;
                        width: 100%;
                    }
                }
            }

            .right {
                position: absolute;
                top: 3px;
                right: 0;

                font-size: 16px;
                font-weight: 500;
                text-decoration: underline;
                color: $color-1;
            }
        }

        article {
            position: relative;
            width: 100%;
            margin-bottom: 20px;

            font-size: 0;

            overflow: hidden;

            @include mappy-bp(medium){ 
                width: calc(50% - 10px);
            }

            a {
                display: inline-block;
                width: 100%;
                height: 100%;

                font-size: 0;
            }

            img {
                display: block;
                max-height: 340px;

                transform: scale(1) translateZ(0);
                transition: all 0.3s ease-in-out;
            }

            .content {
                position: absolute;
                bottom: 0;
                left: 0;

                width: 100%;
                padding: 20px 16px;

                background: rgba(black, 0.75);

                p {
                    margin-bottom: 2px;

                    font-size: 12px;
                    font-weight: 600;
                    line-height: 18px;
                    color: white;
                }

                h4 {
                    margin-bottom: 16px;

                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: white;
                }
            }
        }

        article:hover {
            img {
                transform: scale(1.03) translateZ(0);
            }
        }
    }
    /********************************************/
    
}
