/*********************************************
  :: Site Footer
********************************************/
.site-footer {
    position: relative;

    width: 100%;
    min-height: $footer-height-mobile;
    padding: 40px 0 0;

    font-size: 0;

    background: #171717;
    
    @include mappy-bp(mediumish){
        min-height: $footer-height-desktop;
        padding: 72px 0 0;
    }

    .content-max-width {
        .col:last-of-type {
            padding: 20px 0 0 0;
            @include mappy-bp(mediumish){
                padding: 0 0 0 10px;
            }
        }
    }

    .bottom {
        @include mappy-bp(mediumish){
            margin-top: 74px;
        }

        .copyright,
        .attribution {
            display: inline-block;
            margin: 0;
            
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
            color: rgba(white, 0.5);
        }

        .social {
            align-items: center;
            width: 340px;
            height: 100px;
            margin: 0;

            text-align: center;

            background: $color-1;

            @include mappy-bp(max-width mediumish){
                position: absolute;
                top: -325px;
                left: 0;
                right: 0;

                height: 70px;
                margin: 0 auto;
            }

            a {
                display: inline-block;
                width: 28px;
                height: 28px;

                background-color: white;

                span {
                    font-size: 16px;
                    line-height: 28px;
                    color: $color-1;
                }
            }
        }
    }

    .col {
        display: inline-block;
        vertical-align: top;
        width: 100%;

        text-align: center;

        @include mappy-bp(mediumish){
            width: 50%;

            text-align: left;
        }

        ul {
            margin: 0;

            li {
                margin: 0;

                a {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 30px;
                    color: white;
                }
            }
        }

        h4 {
            margin-bottom: 20px;

            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            color: white;

            @include mappy-bp(mediumish){
                margin-bottom: 65px;
            }
        }

        p {
            margin-bottom: 0;

            font-size: 14px;
            font-weight: 600;
            line-height: 26px;
            color: white;

            @include mappy-bp(mediumish){
                margin-bottom: 20px;
            }

            a, &.small {
                display: inline-block;
                width: 100%;

                font-weight: 400;
                color: white;
            }
        }

        .underline {
            text-decoration: underline;
        }
    }

    .col.menu {
        @include mappy-bp(max-width mediumish){
            order: 1;
            margin: 120px 0 20px;
        }
    }
    .col.contact {
        @include mappy-bp(max-width mediumish){ order: 0; }
    }
}
