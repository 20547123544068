/*********************************************
:: Case Studies Page                   
********************************************/
.page-case-studies {

    .body-container {
        padding: 20px 0 60px;

        background-color: #f9f9f9;

        @include mappy-bp(mediumish){
            padding: 60px 0 80px;
        }

        article {
            width: 100%;
            margin-bottom: 45px;
            padding-bottom: 45px;

            font-size: 0;

            border-bottom: 2px solid #dddddd;

            @include mappy-bp(mediumish){
                
            }

            .content,
            .right {
                display: inline-block;
                vertical-align: top;
            }

            .content {
                width: calc(100% - 265px);

                @include mappy-bp(mediumish){
                    padding-right: 88px;
                }

                h3 {
                    margin-bottom: 5px;

                    font-size: 20px;
                    font-weight: 400;
                    line-height: 42px;
                    color: #2c2c2c;
                }

                p {
                    min-height: 72px;
                    margin-bottom: 18px;
                }

                .more {
                    font-size: 14px;
                    color: $color-1;
                }
            }

            .right {
                width: 265px;
            }
        }

        article:last-of-type {
            margin-bottom: 15px;
        }

        button {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #a9a9a9;
        }

        .load-wrap {
            text-align: center;
        }
    }
    /********************************************/
    
}
