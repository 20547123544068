/*********************************************
  :: Site Header
********************************************/
.site-header {
	position: relative;
	width: 100%;
	max-width: 1440px;
	height: $header-height-mobile;
	z-index: 99;

	@include mappy-bp(mediumish){
		height: $header-height-desktop;
	}

	&.white-logo {
		img.white { display: block !important; }
		img.dark { display: none; }

		.hamburger span {
			color: white;
		}
	}

	.logo {
		position: absolute;
		top: 15px;
		left: 3%;
		bottom: 5px;
		transition: none;

		@include mappy-bp(mediumish){
			top: 28px;
			left: 0;
			width: 324px;
		}

		img {
			height: 30px;
			@include mappy-bp(mediumish){
				height: 45px;	
			}

			&.white { display: none; }
		}
	}

	nav {
		position: absolute;
		top: 0;
		right: 0;

		font-size: 0;
		text-align: right;

		li { 
			position: relative;

			display: inline-block;
			height: 80px;
			margin: 0;
		}

		a {
			display: inline-block;
			height: inherit;
			padding: 48px 17px 0 18px;

			font-size: 14px;
			font-weight: 600;
			line-height: 17px;
			color: rgba(white, 0.85);

			background: $color-1;
			border-right: 1px solid $color-2;

			&.selected,
			&:hover {
				background: $color-2;
 
				&:hover { opacity: 1; }
				&:active { opacity: 1; }
			}
		}
	}

	.hamburger {
        position: absolute;
        top: 25px; 
		right: 3%;

		width: 26px;
    	height: 17px;

		span {
			font-size: 17px;
			color: black;
		}

        &:focus {
            outline: none;
        }
    }
}

/********************************************/