/*********************************************
:: Pagination Module                    
********************************************/
.pagination-module {
	display: inline-block;
	width: 100%;
	margin: 0 auto;
	padding: 20px 0;

	text-align: center;

	ul {
		display: inline-block;
		width: auto;
		height: 34px;
	    margin: 6px;
		padding: 0;

		text-align: center;
		background: $color-2;
		border-radius: 17px;

		@include mappy-bp(mediumish){
			margin: 0;
		}

		li {
			display: inline-block;
			width: 28px;
			height: 28px;
			margin: 3px;

			a {
				font-size: 14px;
				line-height: 28px;
				text-align: center;
				color: $color-1;
			}

			&.active {
				background: $color-1;
				border-radius: 50%;

				a {
					color: white;
				}
			}
		}
	}
}
/********************************************/

