/*********************************************
:: Contact Page                   
********************************************/
.page-contact {

    .contact-container {

        .content-max-width {
            max-width: 863px;
        }

        .col1 {
            float: left;
            width: 100%;
            margin-bottom: 10px;

            @include mappy-bp(mediumish) {
                position: relative;
                left: -7px;
                
                width: 40%;
                max-width: 220px;
                margin-bottom: 24px;
            }

            p {
                display: inline-block;
                width: 100%;
                margin: 0 0 10px;
                padding: 0 0 10px;
                
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;

                border-bottom: 1px solid #9b9b9b;

                @include mappy-bp(mediumish) {
                    margin: 0 0 28px;
                    padding: 0 0 28px;

                    font-size: 16px;
                    line-height: 28px;
                }
            }

            p:last-of-type { border-bottom: none; }
            a { display: block; }

            .phone {
                margin: 0 0 6px;
                
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
                color: $color-1;

                @include mappy-bp(mediumish) {
                    font-size: 22px;
                }
            }

            .email {
                margin-bottom: 20px;

                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                color: $color-1;

                @include mappy-bp(mediumish) {
                    margin-bottom: 40px;

                    font-size: 20px;
                }
            }

        }

        .col2 {
            float: left;
            width: 100%;
            padding: 0 0 40px;

            @include mappy-bp(mediumish) {
                float: right;
                width: 60%;
                max-width: 446px;
                margin: -10px 7px 80px 0;
                padding: 0px;
            }
        }

        /* .map-area {
            .map {
                width: 100%;
                height: 210px;
                margin: auto;

                @include mappy-bp(mediumish) { height: 313px; }
            }
        } */
    }
    /********************************************/
}