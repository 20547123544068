/*********************************************
:: Services Page                   
********************************************/
.page-services {

    .services-container {
        padding: 0 0 60px;

        @include mappy-bp(mediumish){ 
            padding: 0 0 80px;
        }

        article {
            position: relative;
            width: 100%;
            margin-bottom: 40px;
            padding: 20px;

            font-size: 0;

            @include mappy-bp(mediumish){ 
                padding: 50px 90px 54px 60px;
            }

            .col {
                display: inline-block;
                vertical-align: top;
                width: 100%;

                @include mappy-bp(mediumish){ 
                    width: 50%;
                }

                h3 {
                    color: white;
                    @include mappy-bp(mediumish){
                        padding-right: 70px;

                        font-size: 32px;
                        line-height: 42px;
                    }
                }
            }

            .col.content {
                @include mappy-bp(mediumish){ padding: 10px 0 0 25px; }

                p {
                    font-size: 14px;
                    color: white;

                    @include mappy-bp(mediumish){
                        margin-bottom: 25px;

                        font-size: 20px;
                        font-weight: 400;
                        line-height: 30px;
                    }

                    a {
                        font-size: 16px;
                        line-height: 28px;
                        color: $color-1;
                    }
                }
            }
        }

        article:nth-of-type(3n + 1) {
            background: $color-4;
        }

        article:nth-of-type(3n + 2) {
            background: $color-1;
        }

        article:nth-of-type(3n + 3) {
            background: #f9f9f9;

            .col h3,
            .col p { color: #2c2c2c; }
        }

        article:hover {

        }
    }
    /********************************************/
    
}
