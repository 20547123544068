/*********************************************
:: Title Module                    
********************************************/
.title-module {
	padding: 0 0 40px;

	@include mappy-bp(mediumish){
		padding: 71px 0 80px;
	}

	h1 {
		margin: 0;

		font-size: 24px;
		font-weight: $font-semibold;
		line-height: 28px;
		color: #2c2c2c;

		@include mappy-bp(mediumish){
			margin: 0 0 0 90px;

			font-size: 32px;
			font-weight: 600;
			line-height: 42px;
		}

		&:before {
			content: '';
			position: absolute;
			bottom: 8px;
			left: 0;
			
			width: 20px;
			height: 2px;

			background-color: #dddddd;

			@include mappy-bp(mediumish){
				width: 70px;
			}
		}
	}

}
/********************************************/