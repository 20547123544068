/*********************************************
  :: Main Mob Menu                             
********************************************/
body.open-menu {
    #mobile-menu {
        right: 0;
    }
}

#mobile-menu {
    position: fixed;
    top: 0;
    bottom: 0; 
    right: -100%;

    width: 100%;

    text-align: center;

    background: rgba(black, 0.9);
    z-index: 999;
    transition: all .25s;
    -webkit-backface-visibility: hidden;

    @include mappy-bp(mediumish){
        display:none;
    }

    .menu-close-icon {
        position: absolute;
        top: 0; right: 0;

        width: 50px;
        height: 77px;

        border: none;
        outline: none;

        span {
            font-size: 17px;
            line-height: 17px;
            text-align: center;
            color: white;
        }
    }

    .logo {
		position: absolute;
		top: 15px;
		left: 3%;
		bottom: 5px;
		transition: none;

		@include mappy-bp(mediumish){
			top: 28px;
			left: 0;
			width: 324px;
		}

		img {
			height: 30px;
			@include mappy-bp(mediumish){
				height: 45px;	
			}
		}
	}

    .menu-content {
        overflow-y: auto;
        &::-webkit-scrollbar { 
            display: none; 
        }
    }

    a {
        font-family: $font-family-1;
        font-size: 20px;
        font-weight: 600;
        color: white;
        line-height: 24px;

        &.selected { color: $color-1; }
    }

    ul {
        padding: 84px 0 0;
    }

    li {
        margin: 0;
        padding: 0 0 24px;
    }

    .contact {
        a {
            display: block;
            font-size: 18px;
            font-weight: 400;
            line-height: 35px;
        }

        a:first-of-type { text-decoration: underline; }
    }
}