/*********************************************
:: Case Studies & News Detail Pages                   
********************************************/
.page-detail {

    .body-container {
        padding: 20px 0 60px;

        background-color: #f9f9f9;

        @include mappy-bp(mediumish){
            padding: 60px 0;
        }
    }
    /********************************************/
    
}
